import type { IMenuCategory } from "@/types/nav";

const findRootObject = (items: IMenuCategory[], targetKey?: string) => {
  if (targetKey) {
    for (const item of items) {
      if (item.key === targetKey) {
        return item;
      }
      if (item.children) {
        const found = item.children.find((child) =>
          child.key.includes(targetKey)
        );
        if (found) {
          return item;
        }
      }
    }
  }
  return null;
};

export default findRootObject;
